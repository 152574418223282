
//
// Load core variables and mixins
// --------------------------------------------------

@import "variables.less";
@import "mixins.less";

@header-bg: #2ba6cb;

@theme-roosterteeth: #9b1a24;
@theme-ntsu: #df0d81;
@theme-theknow: #2e80b2;
@theme-flyfm: #d1004a;
@theme-ah: #449810;
@theme-rtx: #ee2611;
@theme-argongreen: #111;
@theme-rvbs9: #b50a82;
@theme-rvbs8: #c85713;

.container-fluid {
	max-width: 1200px;
}

body {
	padding-top: 0;
}
header {
	background: @header-bg;
	margin: 0;
	padding: (@grid-gutter-width) 0;
	color: #fff;
	-webkit-font-smoothing: antialiased;
	h1 {
		font-size: 60px;
		margin-top: 0;
		margin-bottom: 15px;
		cursor: default;
		font-family: 'Reenie Beanie', "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif !important;
	}
	p {
		text-shadow: 0 1px 2px darken(@header-bg, 10%);
		width: auto;
		max-width: 750px;
	}
}
header a, footer a {
	font-weight: bold;
	color: inherit;
}
header a:hover, footer a:hover {
	color: inherit;
}

main {
	display: inline-block;
	width: 100%;
}

blockquote {
	border: 0;
	font-size: 13px;
	color: #777;
	position: relative;
}

cite {
	display: block;
	font-style: italic;
	margin-top: 5px;
}

.quotemark {
	font-size: 40px;
	position: absolute;
	top: 15px;
	left: 0;
	color: #999;
	font-family: Georgia, Times New Roman, Helvetica, Arial, sans-serif;
}

footer {
	border-top: 2px solid darken(@body-bg, 4%);
	padding: 20px 0 40px 0;
	margin-top: @grid-gutter-width;
	text-shadow: 0 1px 0 #fff;
	text-align: center;
	color: #777;
}

.thumbs-grid {
	.make-row();
	.thumb {
		.make-xs-column(12);
		.make-sm-column(6);
		.make-md-column(4);
		> div {
			border: 5px solid #fff;
			border-radius: 2px;
			margin-bottom: @grid-gutter-width;
			position: relative;
			overflow: hidden;
			.info {
				opacity: 0;
				position:absolute;
				text-align:center;
				padding: 10px;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				color: #fff;
				z-index: 2;
				-webkit-font-smoothing: antialiased;
				.transition(opacity 0.4s);
				> div {
					position: relative;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					> div {
						position: absolute;
						left: 0%;
						right: 0%;
						top: 50%;
						-webkit-transform: translate(0, -50%);
					}
				}
				hr {
					width: 20%;
					border-top-width: 2px;
					.transition(width 0.25s ease-out);
				}
				p {
					text-transform: uppercase;
					letter-spacing: 1px;
					font-size: @font-size-small;
				}
				.title {
					font-weight: bold;
				}
			}
			.box-shadow(0 4px 4px darken(@body-bg, 8%));
			img {
				max-width: 100%;
				.transition(all 0.4s);
				will-change: filter, -webkit-filter;
				.blur(0);
			}
		}
		> div:hover {
			cursor: pointer;
			.info {
				opacity: 1;
				hr {
					width: 50%;
				}
			}
			img {
				.blur(6px);
			}
		}
	}
}

.blur(@size) {
	-webkit-filter: blur(@size);
	-moz-filter: blur(@size);
	-ms-filter: blur(@size);
	-o-filter: blur(@size);
	filter: blur(@size);
}

.theme (@color) {
	> div {
		.info {
			background-color: fade(@color, 60%);
			text-shadow: 0 0 3px darken(@color, 20%);
			hr {
				border-top-color: lighten(@color, 35%);
			}
		}
	}
	> div:hover {
		background-color: fade(@color, 60%);
	}
}

.thumb-roosterteeth {
	.theme(@theme-roosterteeth);
}
.thumb-ntsu {
	.theme(@theme-ntsu);
}
.thumb-theknow {
	.theme(@theme-theknow);
}
.thumb-flyfm {
	.theme(@theme-flyfm);
}
.thumb-ah {
	.theme(@theme-ah);
}
.thumb-rtx {
	.theme(@theme-rtx);
}

.thumb-argongreen {
	.theme(@theme-argongreen);
}
.thumb-rvbs9 {
	.theme(@theme-rvbs9);
}
.thumb-rvbs8 {
	.theme(@theme-rvbs8);
}

.links {
	cursor: default;
	text-align: center;

	a {
		color: @gray-light;
		font-size: 22px;
		display: inline-block;
		padding: 5px;
		margin: 0 5px;
		vertical-align: middle;
		cursor: pointer;
	}

	a:hover {
		text-decoration: none;
		color: @header-bg;
		border-color: @header-bg;
	}

	.cv {
		border: 1px solid darken(@body-bg, 10%);
		border-radius: 100%;
		padding: 10px;
		font-size: 12px;
		width: 38px;
		height: 38px;
		line-height: 19px;
	}
}

section {
	padding: @grid-gutter-width 0;
	background-color: @body-bg;
}

section:nth-child(odd),
body {
	background-color: darken(@body-bg, 2%);
}

img {
	max-width: 100%;
}

.viewer-desc {
	padding: @grid-gutter-width;
	color: #111;
}

.viewer-body {
	color: #777;
}

@media (max-width: @screen-md) {
	.viewer-desc {
		margin-top: 25px;
	}
	.carousel-indicators li {
		background-color: @gray-lighter;
	}
	.carousel-indicators li.active {
		background-color: @gray-light;
	}
	.ngdialog.ngdialog-theme-default .ngdialog-close::before {
		top: -40px !important;
		right: 0 !important;
	}
	.ngdialog.ngdialog-theme-default .ngdialog-close:hover::before, .ngdialog.ngdialog-theme-default .ngdialog-close:active::before {
		color: #fff !important;
	}
}

.carousel-indicators {
	bottom: -60px;
}
.carousel-control {
	display: none;
}
.viewer-thumbs {
	background: @body-bg;
	text-align: center;
	padding: 10px;
	img {
		width: 100px;
		margin: 5px;
		border: 2px solid #fff;
		opacity: 0.4;
		.box-shadow(0 4px 4px darken(@body-bg, 8%));
	}
	img:hover {
		opacity: 1;
		cursor: pointer;
	}
	img.active, img.active:hover {
		opacity: 1;
	}
}
.title-line {
	border-bottom: 2px solid @gray-lighter;
	margin-top: 35px;
	margin-bottom: 8px;
	font-size: 12px;
	text-transform: uppercase;
	color: @gray-light;
	cursor: default;
	font-weight: bold;
}
.tech-box {
	margin-top: 20px;
}
.tech-label {
	background-color: @gray-lighter;
	color: @gray-light;
	font-weight: bold;
	padding: 4px 8px;
	margin-right: 8px;
	margin-bottom: 8px;
	display: inline-block;
	font-size: 12px;
	cursor: default;
}

//
// Dialog
// --------------------------------------------------

@scale-in-size: 0.90;

@-webkit-keyframes ngdialog-flyin {
	0% {
		opacity: 0;
		-webkit-transform: scale(@scale-in-size);
		transform: scale(@scale-in-size);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes ngdialog-flyin {
	0% {
		opacity: 0;
		-webkit-transform: scale(@scale-in-size);
		-ms-transform: scale(@scale-in-size);
		transform: scale(@scale-in-size);
	}

	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes ngdialog-flyout {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes ngdialog-flyout {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.ngdialog.ngdialog-theme-default {
	padding-bottom: 60px;
	padding-top: 60px;
}

.ngdialog.ngdialog-theme-default.ngdialog-closing .ngdialog-content {
	-webkit-animation: ngdialog-flyout .5s;
	animation: ngdialog-flyout .5s;
}

.ngdialog-overlay {
	background: rgba(0,0,0,.8);
}

.ngdialog.ngdialog-theme-default {
	.row-viewer {
		.make-row(0);
	}
	.row-viewer:before, .row-viewer:after {
		content: " ";
		display: table;
		clear: both;
	}
	.col-viewer-img {
		.make-md-column(8, 0);
		.rn-carousel-container img {
			width: 100%;
		}
	}
	.col-viewer-desc {
		.make-md-column(4, 0);
		overflow-y: scroll;
		overflow-x: hidden;

		h1, h2 {
			margin-top: 0;
		}
	}
	.ngdialog-content {
		-webkit-animation: ngdialog-flyin .5s;
		animation: ngdialog-flyin .5s;
		background: #fff;
		border-radius: 0;
		.box-shadow(0 0 10px rgba(0, 0, 0, 0.4));
		color: #444;
		font-family: 'Helvetica',sans-serif;
		font-size: 1.1em;
		line-height: 1.5em;
		margin: 0 auto;
		max-width: 1500px;
		padding: 0;
		position: relative;
		width: 95%;
	}
	.ngdialog-close {
		border-radius: 5px;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 0;
	}
	.ngdialog-close:before {
		background: transparent;
		border-radius: 3px;
		color: #bbb;
		content: '\00D7';
		font-size: 26px;
		font-weight: 400;
		height: 30px;
		line-height: 26px;
		position: absolute;
		right: 3px;
		text-align: center;
		top: 3px;
		width: 30px;
	}
}


.ngdialog.ngdialog-theme-default .ngdialog-close:hover:before,
.ngdialog.ngdialog-theme-default .ngdialog-close:active:before {
	color: #777;
}

.ngdialog.ngdialog-theme-default .ngdialog-message {
	margin-bottom: .5em;
}

.ngdialog.ngdialog-theme-default .ngdialog-input {
	margin-bottom: 1em;
}

.ngdialog.ngdialog-theme-default .ngdialog-input textarea,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="text"],
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="password"],
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="email"],
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="url"] {
	background: #fff;
	border: 0;
	border-radius: 3px;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	margin: 0 0 .25em;
	min-height: 2.5em;
	padding: .25em .67em;
	width: 100%;
}

.ngdialog.ngdialog-theme-default .ngdialog-input textarea:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="text"]:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="password"]:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="email"]:focus,
.ngdialog.ngdialog-theme-default .ngdialog-input input[type="url"]:focus {
	-webkit-box-shadow: inset 0 0 0 2px #8dbdf1;
	box-shadow: inset 0 0 0 2px #8dbdf1;
	outline: none;
}

.ngdialog.ngdialog-theme-default .ngdialog-buttons {
	*zoom: 1;
}

.ngdialog.ngdialog-theme-default .ngdialog-buttons:after {
	content: '';
	display: table;
	clear: both;
}

.ngdialog.ngdialog-theme-default .ngdialog-button {
	border: 0;
	border-radius: 3px;
	cursor: pointer;
	float: right;
	font-family: inherit;
	font-size: .8em;
	letter-spacing: .1em;
	line-height: 1em;
	margin: 0 0 0 .5em;
	padding: .75em 2em;
	text-transform: uppercase;
}

.ngdialog.ngdialog-theme-default .ngdialog-button:focus {
	-webkit-animation: ngdialog-pulse 1.1s infinite;
	animation: ngdialog-pulse 1.1s infinite;
	outline: none;
}

@media (max-width: 568px) {
	.ngdialog.ngdialog-theme-default .ngdialog-button:focus {
		-webkit-animation: none;
		animation: none;
	}
}

.ngdialog.ngdialog-theme-default .ngdialog-button.ngdialog-button-primary {
	background: #3288e6;
	color: #fff;
}

.ngdialog.ngdialog-theme-default .ngdialog-button.ngdialog-button-secondary {
	background: #e0e0e0;
	color: #777;
}


//
// Buttons
// --------------------------------------------------

// Common styles
.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
	text-shadow: 0 -1px 0 rgba(0,0,0,.2);
	@shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
	.box-shadow(@shadow);

  // Reset the shadow
  &:active,
  &.active {
  	.box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
}

// Mixin for generating new styles
.btn-styles(@btn-color: #555) {
	#gradient > .vertical(@start-color: @btn-color; @end-color: darken(@btn-color, 12%));
  .reset-filter(); // Disable gradients for IE9 because filter bleeds through rounded corners
  background-repeat: repeat-x;
  border-color: darken(@btn-color, 14%);

  &:hover,
  &:focus  {
  	background-color: darken(@btn-color, 12%);
  	background-position: 0 -15px;
  }

  &:active,
  &.active {
  	background-color: darken(@btn-color, 12%);
  	border-color: darken(@btn-color, 14%);
  }

  &:disabled,
  &[disabled] {
  	background-color: darken(@btn-color, 12%);
  	background-image: none;
  }
}

// Common styles
.btn {
  // Remove the gradient for the pressed/active state
  &:active,
  &.active {
  	background-image: none;
  }
}

// Apply the mixin to the buttons
.btn-default { .btn-styles(@btn-default-bg); text-shadow: 0 1px 0 #fff; border-color: #ccc; }
.btn-primary { .btn-styles(@btn-primary-bg); }
.btn-success { .btn-styles(@btn-success-bg); }
.btn-info    { .btn-styles(@btn-info-bg); }
.btn-warning { .btn-styles(@btn-warning-bg); }
.btn-danger  { .btn-styles(@btn-danger-bg); }



//
// Images
// --------------------------------------------------

.thumbnail,
.img-thumbnail {
	.box-shadow(0 1px 2px rgba(0,0,0,.075));
}



//
// Dropdowns
// --------------------------------------------------

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	#gradient > .vertical(@start-color: @dropdown-link-hover-bg; @end-color: darken(@dropdown-link-hover-bg, 5%));
	background-color: darken(@dropdown-link-hover-bg, 5%);
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
	#gradient > .vertical(@start-color: @dropdown-link-active-bg; @end-color: darken(@dropdown-link-active-bg, 5%));
	background-color: darken(@dropdown-link-active-bg, 5%);
}



//
// Navbar
// --------------------------------------------------

// Default navbar
.navbar-default {
	#gradient > .vertical(@start-color: lighten(@navbar-default-bg, 10%); @end-color: @navbar-default-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered
  border-radius: @navbar-border-radius;
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  .navbar-nav > .active > a {
  	#gradient > .vertical(@start-color: darken(@navbar-default-bg, 5%); @end-color: darken(@navbar-default-bg, 2%));
  	.box-shadow(inset 0 3px 9px rgba(0,0,0,.075));
  }
}
.navbar-brand,
.navbar-nav > li > a {
	text-shadow: 0 1px 0 rgba(255,255,255,.25);
}

// Inverted navbar
.navbar-inverse {
	#gradient > .vertical(@start-color: lighten(@navbar-inverse-bg, 10%); @end-color: @navbar-inverse-bg);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered

  .navbar-nav > .active > a {
  	#gradient > .vertical(@start-color: @navbar-inverse-bg; @end-color: lighten(@navbar-inverse-bg, 2.5%));
  	.box-shadow(inset 0 3px 9px rgba(0,0,0,.25));
  }

  .navbar-brand,
  .navbar-nav > li > a {
  	text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  }
}

// Undo rounded corners in static and fixed navbars
.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
	border-radius: 0;
}



//
// Alerts
// --------------------------------------------------

// Common styles
.alert {
	text-shadow: 0 1px 0 rgba(255,255,255,.2);
	@shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.05);
	.box-shadow(@shadow);
}

// Mixin for generating new styles
.alert-styles(@color) {
	#gradient > .vertical(@start-color: @color; @end-color: darken(@color, 7.5%));
	border-color: darken(@color, 15%);
}

// Apply the mixin to the alerts
.alert-success    { .alert-styles(@alert-success-bg); }
.alert-info       { .alert-styles(@alert-info-bg); }
.alert-warning    { .alert-styles(@alert-warning-bg); }
.alert-danger     { .alert-styles(@alert-danger-bg); }



//
// Progress bars
// --------------------------------------------------

// Give the progress background some depth
.progress {
	#gradient > .vertical(@start-color: darken(@progress-bg, 4%); @end-color: @progress-bg)
}

// Mixin for generating new styles
.progress-bar-styles(@color) {
	#gradient > .vertical(@start-color: @color; @end-color: darken(@color, 10%));
}

// Apply the mixin to the progress bars
.progress-bar            { .progress-bar-styles(@progress-bar-bg); }
.progress-bar-success    { .progress-bar-styles(@progress-bar-success-bg); }
.progress-bar-info       { .progress-bar-styles(@progress-bar-info-bg); }
.progress-bar-warning    { .progress-bar-styles(@progress-bar-warning-bg); }
.progress-bar-danger     { .progress-bar-styles(@progress-bar-danger-bg); }

// Reset the striped class because our mixins don't do multiple gradients and
// the above custom styles override the new `.progress-bar-striped` in v3.2.0.
.progress-bar-striped {
	#gradient > .striped();
}


//
// List groups
// --------------------------------------------------

.list-group {
	border-radius: @border-radius-base;
	.box-shadow(0 1px 2px rgba(0,0,0,.075));
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
	text-shadow: 0 -1px 0 darken(@list-group-active-bg, 10%);
	#gradient > .vertical(@start-color: @list-group-active-bg; @end-color: darken(@list-group-active-bg, 7.5%));
	border-color: darken(@list-group-active-border, 7.5%);
}



//
// Panels
// --------------------------------------------------

// Common styles
.panel {
	.box-shadow(0 1px 2px rgba(0,0,0,.05));
}

// Mixin for generating new styles
.panel-heading-styles(@color) {
	#gradient > .vertical(@start-color: @color; @end-color: darken(@color, 5%));
}

// Apply the mixin to the panel headings only
.panel-default > .panel-heading   { .panel-heading-styles(@panel-default-heading-bg); }
.panel-primary > .panel-heading   { .panel-heading-styles(@panel-primary-heading-bg); }
.panel-success > .panel-heading   { .panel-heading-styles(@panel-success-heading-bg); }
.panel-info > .panel-heading      { .panel-heading-styles(@panel-info-heading-bg); }
.panel-warning > .panel-heading   { .panel-heading-styles(@panel-warning-heading-bg); }
.panel-danger > .panel-heading    { .panel-heading-styles(@panel-danger-heading-bg); }



//
// Wells
// --------------------------------------------------

.well {
	#gradient > .vertical(@start-color: darken(@well-bg, 5%); @end-color: @well-bg);
	border-color: darken(@well-bg, 10%);
	@shadow: inset 0 1px 3px rgba(0,0,0,.05), 0 1px 0 rgba(255,255,255,.1);
	.box-shadow(@shadow);
}
